<script>
import Layout from "../../layouts/main";
import StudentLayout from '../../layouts/studentLayout';
import { required, helpers } from "vuelidate/lib/validators";
import errorHandler from "@/helpers/errorHandler";
// import moment from 'moment';

/**
 * Starter component
 */
export default {
  name: "StudentUpdateCredentials",
  page:{
    title: 'Student Info'
  },
  validations: {
    username: {
      required
    },
    new_password: {
      required
    },
  },
  components:{
    Layout,
    StudentLayout
  }, 
  async created(){
    await this.$store.dispatch('student/getStudentInfo',this.$route.params.student).then(()=>{
        this.profileData = this.$store.getters['student/getStudent'];
        this.phone = this.$store.getters['student/getStudent'].user.phone_number.toString().substring(1);
    }).then(()=>{}).catch( err =>{
      // errorHandler.methods.errorMessage(err);
      console.log("No items")
    });
    await this.$store.dispatch('statistics/getStudentStat',this.$route.params.student).then(()=>{
        this.number = this.$store.getters['statistics/studentCount'];
    }).then(()=>{}).catch( err =>{
      // errorHandler.methods.errorMessage(err);
      console.log("No items")
    });
  },
  data() {
    return {
      profileData:{},
      number: 0,
      submitted:false,
      phone: '',
      username: '',
      new_password: ''
    };
  },
  methods:{
    searchStudent() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.$store.dispatch("student/updateStudentCredentials", {
            phone: this.phone,
            username: this.username,
            new_password:this.new_password
        }).then( token =>{
              if(token.detail.state == true){
                errorHandler.methods.successful('Updated successfully!');
                this.$router.push(`/students/${this.$route.params.student}/details`)
              }else{
                errorHandler.methods.errorValue(token.error)
              }
            }).catch((error) => {
              errorHandler.methods.errorMessage(error);
        });
      }
    },
  }
};
</script>

<template>
  <Layout>
    <StudentLayout :student="profileData">
      <div class="row justify-content-center">
            <div class="col-md-6">
                <form action="#" @submit.prevent="searchStudent">
                    <div class="form-group mb-3">
                        <label for="Phoneid" v-if="phone">Phone</label>
                        <p>{{ phone }}</p>
                    </div>
                    <div class="form-group mb-3">
                        <label for="Phoneid">Username</label>
                        <input
                            class="form-control"
                            v-model="username"
                            type="text"
                            id="phoneid"
                            placeholder="Enter student phone number"
                            :class="{ 'is-invalid': submitted && $v.username.$error }"
                        />
                        <div
                            v-if="submitted && $v.username.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.username.required">Username is required.</span>
                        </div>
                    </div>
                    <div class="form-group mb-3">
                        <label for="Phoneid">New password</label>
                        <input
                            class="form-control"
                            v-model="new_password"
                            type="text"
                            id="phoneid"
                            placeholder="Enter student phone number"
                            :class="{ 'is-invalid': submitted && $v.new_password.$error }"
                        />
                        <div
                            v-if="submitted && $v.new_password.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.new_password.required">Password is required.</span>
                        </div>
                    </div>
                    <div class="form-group mb-0 text-center">
                        <button class="btn btn-warning btn-block btn-rounded" type="submit">
                            <i class="fas fa-user-edit"></i>
                            Update
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </StudentLayout>
  </Layout>
</template>

<style scoped>
ul {
  list-style-type: none;
}

.tab-content {
  border: none;
}

.list-group-item {
  border: none;
}

i {
  font-size: 20px;
}
</style>
